import axios from 'axios'
import TokenService from './TokenService'

const privateInstance = axios.create()
const publicInstance = axios.create()

const { getToken, refresh, removeToken } = TokenService()

const AxiosService = () => {

    privateInstance.interceptors.request.use(async config => {
        config.transformRequest = (data) => {
            return data && typeof data !== 'string' ? JSON.stringify(data) : data
        }

        config.withCredentials = true
        config.headers.Accept = 'application/json'
        config.headers['Content-Type'] = 'application/json'
        config.headers['Cache-Control'] = 'no-cache'
        config.headers.Pragma = 'no-cache'
        config.headers.Expires = '0'
        const token = await getToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token.access_token}`
        }
        return config
    })

    privateInstance.interceptors.response.use(async response => response, async error => {
        const message = error.response.data.error
        if (message === 'expired_token') {
            let token = await getToken()
            if (!token) return Promise.reject(error)
            token = await refresh(token.access_token, token.refresh_token)
            if (!token) return Promise.reject(error)
            error.config.headers = {
                ...error.config.headers,
                "Authorization": `Bearer ${token.access_token}`
            }
            error.config.baseURL = undefined
            return privateInstance.request(error.config)
        } else if (message === 'invalid_refresh_token' || message === 'invalid_token') {
            removeToken()
            return Promise.reject(error)
        }
        throw error
    })


    publicInstance.interceptors.request.use(async config => {
        config.headers.Accept = 'application/json'
        config.headers['Content-Type'] = 'application/json'
        config.headers['Cache-Control'] = 'no-cache'
        config.headers.Pragma = 'no-cache'
        config.headers.Expires = '0'
        config.transformRequest = (data) => {
            return data && typeof data !== 'string' ? JSON.stringify(data) : data
        }

        return config
    })

    publicInstance.interceptors.response.use(async response => response, async error => {
        const message = error.response.data
        if (message === 'invalid_refresh_token' || message === 'invalid_token') {
            removeToken()
            return Promise.reject(error)
        }
        throw error
    })
    return {
        privateInstance,
        publicInstance
    }
}

export default AxiosService