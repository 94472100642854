import { useController } from '@data-client/react'
import Cookies from 'js-cookie'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { FullPageLoader } from '../components/loader/Loader'
import { Token, TokenCookie, TokenResource, TokenType } from '../datasource/auth/token'
import TokenService from '../services/TokenService'

type AuthContextProps = {
  authType?: TokenType
  accessToken?: string
  login: (username: string, password: string) => Promise<TokenCookie>
  switchCompany: (companyId: number) => Promise<TokenCookie | undefined>
  authenticated?: boolean
  logout: () => Promise<any>
  userId: number | undefined
  companyId: number | undefined
}

const defaultValues = {
  token: undefined,
  accessToken: '',
  login: (value) => value,
  switchCompany: (value) => value,
  authenticated: false,
  logout: () => new Promise(() => {}),
  userId: undefined,
  companyId: undefined,
}

const Auth = createContext<AuthContextProps>(defaultValues)

function AuthProvider({ children }: PropsWithChildren) {
  const { fetch } = useController()
  const [loading, setLoading] = useState(true)
  const { getToken, setToken, revokeToken } = TokenService()
  const [authenticated, setAuthenticated] = useState<boolean>(Cookies.get('pv-token') !== undefined)
  const [userId, setUserId] = useState<number | undefined>(undefined)
  const [companyId, setCompanyId] = useState<number | undefined>(undefined)

  const login = async (username: string, password: string) => {
    return await fetch(TokenResource.login, {
      username: username,
      password: password,
      remember_me: true,
      grant_type: 'password',
    })
      .then(setAuthenticatedToken)
      .catch((err) => {
        throw err
      })
  }

  const switchCompany = async (companyId: number) => {
    const token = await getToken()
    if (!token) return undefined
    return fetch(TokenResource.exchange, {
      access_token: token.access_token,
      refresh_token: token.refresh_token,
      company_id: companyId,
      grant_type: 'access_token',
    }).then((token) => {
      setCompanyId(companyId)
      setAuthenticatedToken(token)
      return token
    })
  }

  const setAuthenticatedToken = (token: Token) => {
    setUserId(token.user_id)
    setAuthenticated(true)
    return setToken(token)
  }

  const logout = async () => {
    await revokeToken().finally(() => {
      setAuthenticated(false)
      setUserId(undefined)
      setCompanyId(undefined)
    })
  }

  useEffect(() => {
    if (!authenticated) {
      setLoading(false)
      return
    }
    setLoading(true)
    getToken()
      .then((token) => {
        if (token) {
          setCompanyId(token.company_id)
          setUserId(token.user_id)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Auth.Provider
      value={{
        authType: localStorage.getItem('pv-token') as TokenType,
        login,
        switchCompany,
        authenticated,
        userId,
        companyId,
        logout,
      }}
    >
      {loading ? <FullPageLoader loading={true} /> : children}
    </Auth.Provider>
  )
}

const useAuth: () => AuthContextProps = () => useContext(Auth)

export { AuthProvider, useAuth }
