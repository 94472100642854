import { SettingOutlined } from '@ant-design/icons'
import { useAccount } from '@src/sdk/providers/AccountProvider'
import { useAuth } from '@src/sdk/providers/AuthProvider'
import { useCompanySelect } from '@src/sdk/providers/CompanySelectProvider'
import { withPrefix } from '@src/sdk/providers/ConfigProvider'
import { Layout, Menu, MenuProps, Typography } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useLayoutState } from 'src/layouts'
import './Navigation.scss'

const { Header } = Layout

const TopNav: FC = () => {
  const { setPageLoading } = useLayoutState()
  const { selectCompany: companySelectModal } = useCompanySelect()
  const { logout } = useAuth()
  const { company } = useAccount()
  const navigate = useNavigate()

  const navItems: MenuProps['items'] = [
    {
      label: 'Switch Company',
      key: '/companies',
      onClick: companySelectModal,
      icon: <SettingOutlined />,
    },
    {
      label: 'Logout',
      key: '/logout',
      onClick: async () => {
        setPageLoading(true)
        await logout()
          .then(() => {
            navigate('/login')
          })
          .finally(() => setPageLoading(false))
      },
      icon: <SettingOutlined />,
    },
  ]
  return (
    <Header className={withPrefix('top-nav')}>
      {company && (
        <Typography.Title level={4} className={withPrefix('top-nav-company-name')}>
          {company.name}
        </Typography.Title>
      )}
      <Menu selectedKeys={[]} theme={'dark'} items={navItems} mode={'horizontal'} />
    </Header>
  )
}

export default TopNav
