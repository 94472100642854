import { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode"
import moment from "moment"
import { ApiError } from "../api/error"
import { Token, TokenCookie } from "../datasource/auth/token"
import useBase64 from "../hooks/useBase64"
import AxiosService from './AxiosService'
let refreshingFunc: Promise<TokenCookie | undefined> | undefined = undefined;


const TokenService = () => {
    const { base64Encode, base64Decode } = useBase64()

    const setToken = (token: Token) => {
        const cookie = Token.TokenCookie(token)
        Cookies.set('pv-token', base64Encode(cookie), {
            expires: new Date(token.refresh_expires_at),
            path: '/'
        })
        localStorage.setItem('pv-token', token.token_type)
        return cookie
    }

    const validateToken = (cookie: string) => {
        const jwt = base64Decode(cookie)
        try {
            jwt_decode(jwt)
            return true
        } catch {
            return false
        }
    }

    const removeToken = () => {
        Cookies.remove('pv-token')
        localStorage.removeItem('pv-token')
        localStorage.removeItem('pv-token-refreshing')
    }

    const setRefreshStatus = (refreshing: boolean) => localStorage.setItem('pv-token-refreshing', `${refreshing}`)


    const revokeToken = async () => {
        const { publicInstance } = AxiosService()
        const cookie = Cookies.get('pv-token')
        if (!cookie) return
        let token: TokenCookie = JSON.parse(base64Decode(cookie))

        return await publicInstance.request({
            url: `/api/token/revoke`,
            method: 'POST',
            data: {
                access_token: token.access_token,
                refresh_token: token.refresh_token
            }
        })
            .finally(() => removeToken())
    }

    const getToken = async () => {
        const cookie = Cookies.get('pv-token')
        if (!cookie || !validateToken(cookie)) {
            removeToken()
            return undefined
        }

        let token: TokenCookie = JSON.parse(base64Decode(cookie))
        try {
            const expiresAt = moment(token.expires_at).utc()
            if (moment().utc().add(5, 'minutes').isSameOrAfter(expiresAt)) {
                if (!refreshingFunc) {
                    refreshingFunc = refresh(token.access_token, token.refresh_token)
                }
                const new_token = await refreshingFunc
                if (new_token === undefined) {
                    removeToken()
                    window.location.href = "/login"
                    return undefined
                }
                return new_token
            } else {
                return token
            }
        } catch {
            return undefined
        } finally {
            setRefreshStatus(false)
        }
    }

    const refresh = async (accessToken: string, refreshToken: string) => {
        const { publicInstance } = AxiosService()
        setRefreshStatus(true)
        return await publicInstance.request({
            url: `/api/token/refresh`,
            method: 'POST',
            data: {
                access_token: accessToken,
                refresh_token: refreshToken
            }
        })
            .then(async (response) => {
                const token = Token.fromJS(response.data)

                if (!token.access_token) return undefined
                const token_cookie = setToken(token)
                return token_cookie
            })
            .catch(async (err: AxiosError) => {
                const response = err.response
                const apiError = new ApiError(response?.status ?? 400, response?.data)
                if (apiError.error === 'invalid_refresh_token' || apiError.error === 'invalid_token') {
                    removeToken()
                    window.location.href = "/login"
                    throw err
                }
                console.log('Error Refreshing', apiError.error)
                throw err
            })
            .finally(() => setRefreshStatus(false))
    }

    return {
        revokeToken,
        getToken,
        setToken,
        removeToken,
        refresh
    }
}

export default TokenService