import { MenuOutlined } from '@ant-design/icons'
import FullLogo from '@src/sdk/components/logo/LogoFull'
import { BreakpointMin } from '@src/sdk/components/screen/Breakpoints'
import { useAccount } from '@src/sdk/providers/AccountProvider'
import { withPrefix } from '@src/sdk/providers/ConfigProvider'
import { Button, Layout, Menu } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteMap } from 'src/containers/RouteMap'
import './Navigation.scss'

const { Sider } = Layout

const SideNav: FC = () => {
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.MD })
  const [collapsed, setCollapsed] = useState(!isDesktop)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { company, pvAdmin } = useAccount()

  useEffect(() => {
    setCollapsed(!isDesktop)
  }, [isDesktop])

  const onSelect = (item) => {
    navigate(item.key)
    !isDesktop && setCollapsed(true)
  }
  const menuItems = Object.keys(RouteMap)
    .filter((key) => RouteMap[key].navItem)
    .filter((key) => (!pvAdmin ? !RouteMap[key].superAdmin : RouteMap[key]))
    .map((key) => {
      const item = RouteMap[key]
      return {
        label: item.name,
        key: item.path,
      }
    })
  const selectedKey = () => {
    const key = menuItems.find((item) => item.key === pathname)
    return key ? [key.key] : [`/${pathname.replace('/', '').split('/')[0]}`]
  }
  return (
    <div className={withPrefix('side-nav-wrapper')}>
      <div className={withPrefix('side-nav-collapse-container')}>
        <Button
          className={withPrefix('side-nav-collapse')}
          icon={<MenuOutlined />}
          onClick={() => setCollapsed(!!!collapsed)}
        />
      </div>
      <Sider collapsed={collapsed} trigger={null} collapsedWidth={0} width={300} className={withPrefix('side-nav')}>
        <div className={withPrefix('logo-container')}>
          <a href='/'>
            <FullLogo />
          </a>
        </div>
        <Menu mode={'inline'} selectedKeys={selectedKey()} items={menuItems} onClick={onSelect} />
      </Sider>
    </div>
  )
}

export default SideNav
