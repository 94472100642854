import { Result } from 'antd'
import { useEffect } from 'react'
import { ErrorBoundary as Boundary } from 'react-error-boundary'
import { useLocation } from 'react-router'
import { ApiError } from '../api/error'

const Centered = ({ children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>{children}</div>
  )
}

const ErrorComponent = ({ error, resetErrorBoundary }) => {
  const location = useLocation()

  useEffect(() => resetErrorBoundary, [location])
  if (error instanceof ApiError) {
    switch (error?.code) {
      case 403:
        return (
          <Centered>
            <Result status={'403'} title={`${error.code}`} subTitle={error.message} />
          </Centered>
        )
      case 404:
      case 406:
        return (
          <Centered>
            <Result status={'403'} title={`${error.code}`} subTitle={error.message} />
          </Centered>
        )
      default:
        return (
          <Centered>
            <Result status={'error'} title={`${error.code}`} subTitle={error.message} />
          </Centered>
        )
    }
  }
  return <Result status={'error'} title={error.name} subTitle={error.message} />
}

const ErrorBoundary = ({ children }) => {
  return <Boundary FallbackComponent={ErrorComponent}>{children}</Boundary>
}

export { ErrorBoundary }
