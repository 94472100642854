import { Entity } from "@data-client/rest"
import { ApiEndpoint } from "../ApiResource"
import { Company } from "../company/company"
import { User } from "../user/user"

export type TokenType = 'user' | 'user_company'
export type GrantType = 'password' | 'access_token'

export interface TokenCookie {
    access_token: string
    refresh_token: string
    expires_at: Date
    refresh_expires_at: Date
    user_id: number
    company_id: number
    token_type: TokenType
}

type TokenRefresh = {
    access_token: string
    refresh_token: string
}

type TokenRevoke = {
    access_token: string
    refresh_token: string
}

type TokenExchange = {
    access_token: string
    refresh_token: string
    company_id: number
    grant_type: GrantType
}

export type AuthCredentials = {
    readonly username: string
    readonly password: string
    readonly remember_me: boolean
    readonly grant_type: GrantType
}

class Token extends Entity {
    readonly access_token: string = ''
    readonly refresh_token: string = ''
    readonly expiresIn: number = 0
    readonly refresh_expires_in: number = 0
    readonly session_id: string = ''
    readonly company_id: number = 0
    readonly token_type: TokenType = 'user'
    readonly scopes?: string[]
    readonly company: Company = Company.fromJS()
    readonly user?: User = User.fromJS()
    readonly user_id: number = 0
    readonly sso_token: string = ''
    readonly expires_at: Date = new Date()
    readonly refresh_expires_at: Date = new Date()

    static schema: {
        expires_at: Date,
        refresh_expires_at: Date,
        company: Company,
        user: User
    }

    static TokenCookie(token: Token): TokenCookie {
        return {
            access_token: token.access_token,
            refresh_token: token.refresh_token,
            user_id: token.user_id,
            expires_at: token.expires_at,
            refresh_expires_at: token.refresh_expires_at,
            token_type: token.token_type,
            company_id: token.company?.id
        }
    }
    pk(): string {
        return this.access_token
    }
}

const Login = new ApiEndpoint({
    path: '/token',
    method: 'POST',
    process(value): Token { return value },
    body: {} as AuthCredentials
})

const Exchange = new ApiEndpoint({
    path: '/token',
    method: 'POST',
    process(value): Token { return value },
    body: {} as TokenExchange
})

const Refresh = new ApiEndpoint({
    path: '/token/refresh',
    method: 'POST',
    process(value): Token { return value },
    body: {} as TokenRefresh
})

const Revoke = new ApiEndpoint({
    path: '/token/revoke',
    method: 'POST',
    body: {} as TokenRevoke
})

const TokenResource = {
    login: Login,
    refresh: Refresh,
    exchange: Exchange,
    revoke: Revoke
}
export { Token, TokenResource }
