import { ComponentType, lazy } from 'react'

const RouteMap = {
  login: {
    path: '/login',
    name: 'Login',
  },
  logout: {
    path: '/logout',
    name: 'Logout',
  },
  companies: {
    path: '/companies',
    name: 'Companies',
  },
  index: {
    path: '/',
    name: 'Home',
  },
  home: {
    path: '/home',
    name: 'Home',
  },
  domains: {
    path: '/domains',
    name: 'Domains',
    navItem: true,
  },
  domain: {
    path: '/domains/:id',
    name: 'Domain',
  },
  integrations: {
    path: '/integrations',
    name: 'Integrations',
    navItem: true,
  },
  integration: {
    path: '/integrations/:id',
    name: 'Integration',
  },
  webhooks: {
    path: '/webhooks',
    name: 'Webhooks',
  },
  companySettings: {
    path: '/settings',
    name: 'Settings',
    navItem: true,
  },
  apiTokens: {
    path: '/tokens',
    name: 'Api Tokens',
    navItem: true,
  },
  apiToken: {
    path: '/tokens/:id',
    name: 'Api Tokens',
  },
  app: {
    path: '/apps',
    name: 'App',
    navItem: true,
  },
  appDeployments: {
    path: '/apps/deployments',
    name: 'App Deployments',
    navItem: false,
  },
  appDeployment: {
    path: '/apps/deployments/:id',
    name: 'App Deployments',
    navItem: false,
  },
  users: {
    path: '/users',
    name: 'Users',
    navItem: true,
    superAdmin: true,
  },
  queue: {
    path: '/queue',
    name: 'Job Queue',
    navItem: true,
    superAdmin: true,
  },
  logs: {
    path: '/logs',
    name: 'Logs',
    navItem: true,
  },
  eventLogs: {
    path: '/logs/event-logs',
    name: 'Event Logs',
    navItem: true,
  },
  errorLogs: {
    path: '/logs/error-logs',
    name: 'Error Logs',
    navItem: true,
    superAdmin: true,
  },
} as const

export type RouteMapProps = {
  path: string
  nested?: string[]
  protected: boolean
  superAdmin?: boolean
  Component: ComponentType
}

const MappedRoutes: RouteMapProps[] = [
  {
    path: RouteMap.login.path,
    Component: lazy(() => import('src/components/auth/Login')),
    protected: false,
  },
  {
    path: RouteMap.index.path,
    Component: lazy(() => import('src/components/home/Home')),
    nested: ['home'],
    protected: true,
  },
  {
    path: RouteMap.companySettings.path,
    Component: lazy(() => import('src/components/company/CompanySettings')),
    protected: true,
  },
  {
    path: RouteMap.domains.path,
    Component: lazy(() => import('src/components/domains/Index')),
    nested: [':id'],
    protected: true,
  },
  {
    path: RouteMap.apiTokens.path,
    Component: lazy(() => import('src/components/tokens/Index')),
    nested: [':id'],
    protected: true,
  },
  {
    path: RouteMap.integrations.path,
    Component: lazy(() => import('src/components/integrations/Index')),
    protected: true,
    nested: [':id'],
  },
  {
    path: RouteMap.webhooks.path,
    Component: lazy(() => import('src/components/webhooks/Webhooks')),
    protected: true,
  },
  {
    path: RouteMap.app.path,
    Component: lazy(() => import('src/components/apps/Apps')),
    protected: true,
  },
  {
    path: RouteMap.appDeployments.path,
    Component: lazy(() => import('src/components/apps/AppDeployments')),
    protected: true,
    nested: [':id'],
  },
  {
    path: RouteMap.users.path,
    Component: lazy(() => import('src/components/users/Index')),
    nested: [':id'],
    protected: true,
  },
  {
    path: `${RouteMap.queue.path}`,
    Component: lazy(() => import('src/components/queue/JobQueue')),
    protected: true,
    superAdmin: true,
  },
  {
    path: RouteMap.logs.path,
    Component: lazy(() => import('src/components/logs/Index')),
    nested: ['error-logs', 'event-logs'],
    protected: true,
  },
  // {
  //   path: RouteMap.eventLogs.path,
  //   Component: lazy(() => import('src/components/logs/Index')),
  //   protected: true,
  // },
  // {
  //   path: RouteMap.errorLogs.path,
  //   Component: lazy(() => import('src/components/logs/errors/ErrorLogs')),
  //   protected: true,
  //   superAdmin: true,
  // },
  {
    path: `${RouteMap.queue.path}/:type`,
    Component: lazy(() => import('src/components/queue/Jobs')),
    protected: true,
    superAdmin: true,
  },
  {
    path: '*',
    Component: lazy(() => import('src/components/results/NotFound')),
    protected: false,
  },
]
export { MappedRoutes, RouteMap }
